(function ($) {
    "use strict";

    var djJs = {
        init: function () {
            djJs.cacheDom();
            djJs.bindEvents();
        },
        cacheDom: function () {
            this.$window = $(window);
            this.$document = $(document);
            this.$body = $("body");
            this.$html = $("html");
        },
        bindEvents: function () {
            this.salActive();
            this.stickyHeaderMenu();
            this.magnigyPopup();
            this.swiperSlider();
            this.counterUp();
            this.popupMobileMenu();
            this.searchPopup();
            this.widgetToggle();
            this.sitePreloader();
            this.countdownInit(".countdown", "2024/12/30");
        },

        sitePreloader: function () {
            $(window).on("load", function () {
                $("#wskill-preloader").fadeOut();
            });

            $(".preloader-close-btn").on("click", function (e) {
                e.preventDefault();
                $("#wskill-preloader").fadeOut();
            });
        },

        stickyHeaderMenu: function () {
            $(window).on("scroll", function () {
                if ($("body").hasClass("sticky-header")) {
                    var stickyPlaceHolder = $("#wskill-sticky-placeholder"),
                        menu = $(".header-mainmenu"),
                        menuH = menu.outerHeight(),
                        topHeaderH = $(".header-top-bar").outerHeight() || 0,
                        targrtScroll = topHeaderH + 200;

                    if ($(window).scrollTop() > targrtScroll) {
                        menu.addClass("wskill-sticky");
                        stickyPlaceHolder.height(menuH);
                    } else {
                        menu.removeClass("wskill-sticky");
                        stickyPlaceHolder.height(0);
                    }
                }
            });
        },

        salActive: function () {
            sal({
                threshold: 0.01,
                once: true,
            });
        },

        magnigyPopup: function () {
            $(".video-popup-activation").magnificPopup({
                type: "iframe",
            });
        },

        widgetToggle: function () {
            $(".widget-toggle").on("click", function () {
                var $this = $(this),
                    $parents = $this.parents(".wskill-course-widget");

                if (!$parents.hasClass("collapsed")) {
                    $parents.addClass("collapsed");
                    $this.next(".content").slideUp(400);
                } else {
                    $parents.removeClass("collapsed");
                    $this.next(".content").slideDown(400);
                }
            });
        },

        swiperSlider: function () {
            new Swiper(".home-one-testimonial-activator", {
                slidesPerView: 1,
                loop: true,
                autoplay: {
                    delay: 3500,
                },
                breakpoints: {
                    577: {
                        slidesPerView: 2,
                    },
                },
            });
        },

        counterUp: function () {
            $(".counter-item").each(function () {
                if ($(this).is(":in-viewport")) {
                    document
                        .querySelectorAll(".odometer")
                        .forEach(function (el) {
                            el.innerHTML = el.getAttribute(
                                "data-odometer-final"
                            );
                        });
                }
            });

            $(window).on("scroll", function () {
                $(".counter-item").each(function () {
                    if ($(this).is(":in-viewport")) {
                        document
                            .querySelectorAll(".odometer")
                            .forEach(function (el) {
                                el.innerHTML = el.getAttribute(
                                    "data-odometer-final"
                                );
                            });
                    }
                });
            });
        },

        popupMobileMenu: function () {
            $(".hamberger-button").on("click", function () {
                $(".popup-mobile-menu").addClass("active");
            });
            $(".close-menu").on("click", function () {
                $(".popup-mobile-menu").removeClass("active");
                $(".popup-mobile-menu .mainmenu .has-droupdown > a")
                    .siblings(".submenu, .mega-menu")
                    .removeClass("active")
                    .slideUp("400");
                $(
                    ".popup-mobile-menu .mainmenu .has-droupdown > a"
                ).removeClass("open");
            });

            $(".popup-mobile-menu .mainmenu .has-droupdown > a").on(
                "click",
                function (e) {
                    e.preventDefault();
                    $(this)
                        .siblings(".submenu, .mega-menu")
                        .toggleClass("active")
                        .slideToggle("400");
                    $(this).toggleClass("open");
                }
            );

            $(".popup-mobile-menu, .splash-mobile-menu .mainmenu li a").on(
                "click",
                function (e) {
                    if (e.target === this) {
                        $(".popup-mobile-menu").removeClass("active");
                        $(".popup-mobile-menu .mainmenu .has-droupdown > a")
                            .siblings(".submenu, .mega-menu")
                            .removeClass("active")
                            .slideUp("400");
                        $(
                            ".popup-mobile-menu .mainmenu .has-droupdown > a"
                        ).removeClass("open");
                    }
                }
            );
        },

        searchPopup: function () {
            $(".search-trigger").on("click", function () {
                $(".wskill-search-popup").addClass("open");
            });
            $(".close-trigger").on("click", function () {
                $(".wskill-search-popup").removeClass("open");
            });
            $(".wskill-search-popup").on("click", function () {
                $(".wskill-search-popup").removeClass("open");
            });
            $(".wskill-search-popup .wskill-search-popup-field").on(
                "click",
                function (e) {
                    e.stopPropagation();
                }
            );
        },

        countdownInit: function (countdownSelector, countdownTime) {
            var eventCounter = $(countdownSelector);
            if (eventCounter.length) {
                eventCounter.countdown(countdownTime, function (e) {
                    $(this).html(
                        e.strftime(
                            "<div class='countdown-section'><div><div class='countdown-number day'>%D</div> <div class='countdown-unit'>Day%!D</div> </div></div><div class='countdown-section'><div><div class='countdown-number hour'>%H</div> <div class='countdown-unit'>Hrs%!H</div> </div></div><div class='countdown-section'><div><div class='countdown-number minute'>%M</div> <div class='countdown-unit'>Mints</div> </div></div><div class='countdown-section'><div><div class='countdown-number second'>%S</div> <div class='countdown-unit'>Sec</div> </div></div>"
                        )
                    );
                });
            }
        },
    };

    $(document).ready(function () {
        djJs.init();
    });
})(window.jQuery);

// Lazy load video
document.addEventListener("DOMContentLoaded", function () {
    if ($("#background-video").length) {
        const video = document.getElementById("background-video");
        const videoSource = video.querySelector("source");

        const lazyLoadVideo = () => {
            videoSource.src = "videos/wsg-banner.mp4";
            video.load();
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    lazyLoadVideo();
                    observer.disconnect();
                }
            });
        });

        observer.observe(document.querySelector(".hero-banner"));
    }
});

$(document).ready(function () {
    if ($(".show-password").length) {
        console.log(1);
        $(".show-password").on("click", function () {

            console.log(2);
            var passwordField = $(this).siblings("input");
            var passwordFieldType = passwordField.attr("type");
            var icon = $(this).find("i");

            if (passwordFieldType === "password") {
                passwordField.attr("type", "text");
                icon.removeClass("bi-eye").addClass("bi-eye-slash");
            } else {
                passwordField.attr("type", "password");
                icon.removeClass("bi-eye-slash").addClass("bi-eye");
            }
        });
    }
});
