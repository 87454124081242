import '../bootstrap.js';

import jQuery from 'jquery';
window.$ = jQuery;

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import sal from 'sal.js';
window.sal = sal;

import magnificPopup from 'magnific-popup';
window.magnificPopup = magnificPopup;

import countdown from 'jquery-countdown';
window.countdown = countdown;

import Swiper from 'swiper';
window.Swiper = Swiper;

import 'is-in-viewport';

import './theme.js';


